@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield !important;
    }
 }

 .rmsc {
  --rmsc-h: 52px !important;
  --rmsc-radius: 8px !important;
  --rmsc-outline: none !important;
  --rmsc-text: #191919 !important;
  --rmsc-position: static !important;
 }